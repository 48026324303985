<template>
  <div id="clients">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{msg("Filters")}}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16" />
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row v-bind:style="{'max-height':(showMore ? '124px': '500px'),'overflow':(showMore ? 'hidden': 'visible')}">
          <!-- Document -->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg("Document number")}}</label>
            <b-form-input
                v-model="filter.docNum"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- First name-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg("First name")}}</label>
            <b-form-input
                v-model="filter.firstname"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Last name-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg("Last name")}}</label>
            <b-form-input
                v-model="filter.lastname"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Phone-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg("Phone")}}</label>
            <b-form-input
                v-model="filter.phone"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Email-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg("Email")}}</label>
            <b-form-input
                v-model="filter.email"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Address-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg("Address")}}</label>
            <b-form-input
                v-model="filter.address"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Language-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg('Language')}}</label>
            <v-select
                :dir="isRTL ? 'rtl' : 'ltr'"
                :value="filter.lang"
                :options="allLanguage"
                class="w-100"
                label="language"
                :reduce="(lang) => lang.code"
                @input="(val) => $emit('update:roleFilter', val)"
            />
          </b-col>
          <!-- Country-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg('Country')}}</label>
            <v-select
                :dir="isRTL ? 'rtl' : 'ltr'"
                :value="filter.country"
                :options="allCountries"
                class="w-100"
                label="name"
                :reduce="(lang) => lang.iso"
                @input="(val) => $emit('update:roleFilter', val)"
            />
          </b-col>
          <!--Creation Time-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg("Creation Time")}}</label>
            <VueCtkDateTimePicker :button-now-translation="msg('Now')" range no-shortcuts
                                  v-model="creationDatePicker"
                                  :locale="currentFormattedLang" :only-date="true"
                                  format="YYYY-MM-DD" formatted="ll" no-header
            >
              <b-form-input
                  v-model="creationDatePickerStr"
                  class="d-inline-block mr-1"
              />
            </VueCtkDateTimePicker>
          </b-col>
          <!--Assignation Time-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg("Assignation Time")}}</label>
            <VueCtkDateTimePicker :button-now-translation="msg('Now')" range no-shortcuts
                                  v-model="assignationDatePicker"
                                  :locale="currentFormattedLang" :only-date="true"
                                  format="YYYY-MM-DD" formatted="ll" no-header
            >
              <b-form-input
                  v-model="assignationDatePickerStr"
                  class="d-inline-block mr-1"
              />
            </VueCtkDateTimePicker>
          </b-col>
          <!-- Zip-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg("Zip")}}</label>
            <b-form-input
                v-model="filter.zip"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!-- Tag-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg("Tag")}}</label>
            <b-form-input
                v-model="filter.tag"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!--External ID-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{msg("External ID")}}</label>
            <b-form-input
                v-model="filter.externalid"
                class="d-inline-block mr-1"
            />
          </b-col>
          <!--TODO: Representative,Affiliate,Campaign-->
        </b-row>
        <b-row>
          <b-col cols="12" class="text-center my-1 p-0">
            <b-link href="#"  @click="showMore=showMore!==true" >{{msg(showMore ? 'show more' : 'show less')}}</b-link>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Columns-->
<!--    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          {{msg("Columns")}}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row v-bind:style="{'max-height':(showMore ? '124px': '500px'),'overflow':(showMore ? 'hidden': 'visible')}">
          <b-col cols="12" class="text-center my-1 p-0">
            <b-link href="#"  @click="showMore=showMore!==true" >{{msg(showMore ? 'show more' : 'show less')}}</b-link>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>-->

    <b-card no-body class="mb-0">
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{msg('Show')}}</label>
            <v-select
                v-model="amountPerPage"
                :dir="isRTL ? 'rtl' : 'ltr'"
                :options="[10, 25, 50, 100]"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>{{msg('entries')}}</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="filter.search"
                  class="d-inline-block mr-1"
                  :placeholder="msg('Search')"
              />
              <b-button variant="primary" @click="onClientSelect(0)" >
                <span class="text-nowrap">{{msg('New Renter')}}</span>
              </b-button>

            </div>
          </b-col>
        </b-row>

      </div>
      <!--Table-->
      <client-table :filter="filter" @clientSelect="onClientSelect" is-renter
                    :columns="{company:isCompanyClients}" v-bind:amountPerPage="amountPerPage"
                    client-operations :deleteStatus="0"/>
    </b-card>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import clientModal from '../../components/modals/clientModal'
import clientTable from '../../components/components/clientTable'

export default {
  name: 'clients',
  components: {  clientTable },
  data() {
    return {
      filter: {
        creationDateFrom: null,
        creationDateUtil: null,
        assignationDateFrom: null,
        assignationDateUtil: null,
        active: 1,
      },
      showMore:true,
      amountPerPage:10,

      //Modal
      selected: null,

      //Dialogs to show
      dialogs: {
        client: false,
        upload: false
      },

      file: {},
      buFileUpload: null,
      popups: {
        addGroup: false,
        tag: false
      }

    }
  },

  computed: {
    ...mapGetters('translator', ['msg', 'currentFormattedLang']),
    ...mapGetters('data', ['getAllCampaigns', 'getAllAffiliate', 'getOtherUsers', 'getUsersByRole', 'getUsersNotByRole', 'getUserFullName', 'getUserAcronyms', 'getAllLanguage', 'getAllCountries', 'getAllUsers', 'getAllBranches', 'isEnergyOrg', 'isCompanyClients']),
    ...mapGetters('clients', ['rep3Alias', 'getStatus', 'getAllStatus', 'roleInstalIs', 'roleCommIs', 'roleConfirmIs', 'canEditRep']),

    allCountries(){
      return Object.values(this.getAllCountries);
    },

    allLanguage(){
      return Object.values(this.getAllLanguage);
    },
    tags() {
      return {
        client: ['firstname', 'lastname', 'email', 'username', 'password', 'external'],
        organization: ['org_name', 'org_phone'],
        order: ['order_total'],
        calendar: ['appointment'],
        login: ['token'],
        form: ['f(formulaire id)_p(position question)'],
      }
    },
    creationDatePicker: {
      get() {
        return {
          start: this.filter.creationDateFrom,
          end: this.filter.creationDateUtil
        }
      },
      set(v) {
        if (v == null) {
          v = {}
        }
        this.filter.creationDateFrom = v.start
        this.filter.creationDateUtil = v.end
      }
    },
    creationDatePickerStr() {
      if (this.creationDatePicker.start == null) {
        return ''
      }
      let str = this.$moment(this.creationDatePicker.start)
          .format('DD/MM/YY')
      if (this.creationDatePicker.end == null) {
        return str
      }
      return str + ' - ' + this.$moment(this.creationDatePicker.end)
          .format('DD/MM/YY')
    },
    assignationDatePicker: {
      get() {
        return {
          start: this.filter.assignationDateFrom,
          end: this.filter.assignationDateUtil
        }
      },
      set(v) {
        if (v == null) {
          v = {}
        }
        this.filter.assignationDateFrom = v.start
        this.filter.assignationDateUtil = v.end
      }
    },
    assignationDatePickerStr() {
      if (this.assignationDatePicker.start == null) {
        return ''
      }

      let str = this.$moment(this.assignationDatePicker.start)
          .format('DD/MM/YY')
      if (this.assignationDatePicker.end == null) {
        return str
      }

      return str + ' - ' + this.$moment(this.assignationDatePicker.end)
          .format('DD/MM/YY')
    }
  },

  methods: {
    ...mapActions('clients', {
      _uploadFile: 'uploadClientsFile',
    }),
    addNotAssign(arr) {
      let $this = this
      if (typeof arr === 'object') {
        arr = Object.values(arr)
      }
      return [{
        id: -1,
        status: $this.msg('Not assign'),
        firstname: $this.msg('Not assign'),
        lastname: ''
      }, ...arr]
    },
    refresh() {
      console.log('clients->refresh()')
      this.$root.$emit('crm::client-table::refresh')
      this.file.buid = null
    },
    dismissPopover() {
      this.$root.$emit('bv::hide::popover')
      Object.keys(this.operations)
          .forEach((k) => this.operations[k] = false)
    },
    onClientSelect(client) {
      this.selected = client.id
      this.dialogs.client = true
      this.$root.$emit('bv::hide::popover')
      this.$root.$emit('crm::client::select',client.id)

    },
    onNewClient(clientId) {
      console.log('Clients -> onNewClient()', clientId)
      clientId = parseInt(clientId)
      if (!isNaN(clientId) && clientId > 0) {
        this.selected = clientId
        this.refresh()
      }
    },
    onClientUpdate(status) {
      console.log('onCloentUpdate was called with status: ', status)
      if (status === true) {
        this.refresh()
      }
    },
    filterUpdate() {
      this.selectedRows = []
      this.refresh()

    },
    closeDialog() {
      let $this = this
      $this.dialogs.client = false
      setTimeout(() => $this.selected = null, 500)
    },
    uploadFile() {
      let $this = this
      console.log('uploadfile', this.buFileUpload)
      this._uploadFile({
        file: this.file,
        buFileUpload: this.buFileUpload
      })
          .finally(() => $this.refresh());

    }
  }
}
</script>

<style>

</style>
